import { Deck } from '@deck.gl/core';
import { GeoJsonLayer, ArcLayer } from '@deck.gl/layers';

export class BaseDeck {
    constructor() {
        this.COUNTRIES = '../assets/data/world-medium.geo.json';
        this.AIR_PORTS = 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_airports.geojson';

        this.INITIAL_VIEW_STATE = {
            latitude: 46.47,
            longitude: -30,
            zoom: 2,
            bearing: 0,
            pitch: 60,
        };

        this.canvas = 'deckgl';
        this.width = '100%';
        this.height = '100%';
    }

    draw() {
        this.deck = new Deck({
            canvas: this.canvas,
            width: this.width,
            height: this.height,
            initialViewState: this.INITIAL_VIEW_STATE,
            controller: true,
            layers: [
                new GeoJsonLayer({
                    id: 'base-map',
                    data: this.COUNTRIES,
                    // Styles
                    stroked: true,
                    filled: true,
                    lineWidthMinPixels: 1,
                    opacity: 0.02,
                    // getLineDashArray: [30, 3],
                    getLineColor: [0, 0, 0],
                    getFillColor: [230, 230, 230],
                }),
                new GeoJsonLayer({
                    id: 'airports',
                    data: this.AIR_PORTS,
                    // Styles
                    filled: true,
                    pointRadiusMinPixels: 2,
                    pointRadiusScale: 2000,
                    getRadius: f => 11 - f.properties.scalerank,
                    getFillColor: [73, 94, 100, 180],
                    // Interactive props
                    pickable: true,
                    autoHighlight: true,
                    onClick: info => info.object,
                }),
                new ArcLayer({
                    id: 'arcs',
                    data: this.AIR_PORTS,
                    dataTransform: d => d.features.filter(f => f.properties.scalerank < 4),
                    // Styles

                    getSourcePosition: () => [-73.45, 46.47], // London
                    getTargetPosition: f => f.geometry.coordinates,
                    getSourceColor: [197, 191, 50],
                    getTargetColor: [197, 191, 50],
                    getWidth: 1,
                }),
            ],
        });
    }
}

export default BaseDeck;
