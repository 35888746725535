import { Deck } from '@deck.gl/core';
import { GeoJsonLayer } from '@deck.gl/layers';
import { ScreenGridLayer } from '@deck.gl/aggregation-layers';

export class ScreenGridDeck {
    constructor(map, data, initialView) {
        this.MAP = map;
        this.DATA = data;
        this.INITIAL_VIEW_STATE = initialView;

        this.canvas = 'deckgl';
        this.width = '100%';
        this.height = '100%';
    }

    draw() {
        this.deck = new Deck({
            canvas: this.canvas,
            width: this.width,
            height: this.height,
            initialViewState: this.INITIAL_VIEW_STATE,
            controller: true,
            layers: [
                new GeoJsonLayer({
                    id: 'base-map',
                    data: this.MAP,
                    stroked: true,
                    filled: true,
                    lineWidthMinPixels: 1,
                    opacity: 0.04,
                    getLineColor: [60, 60, 60],
                    getFillColor: [230, 230, 255],
                }),
                new ScreenGridLayer({
                    data: this.DATA,
                    id: 'screen-grid-layer',
                    pickable: false,
                    opacity: 0.8,
                    cellSizePixels: 10,
                    colorRange: [
                        [0, 25, 0, 25],
                        [0, 85, 0, 85],
                        [0, 127, 0, 127],
                        [0, 170, 0, 170],
                        [0, 190, 0, 190],
                        [0, 255, 0, 255],
                    ],
                    getPosition: f => [Number(f.Longitude), Number(f.Latitude)],
                    getWeight: d => Number(d['Gross Energy (GWh)']),
                }),
            ],

        });
    }
}

export default ScreenGridDeck;
