/* eslint-disable import/no-extraneous-dependencies */
import { Deck } from '@deck.gl/core';
import { GeoJsonLayer } from '@deck.gl/layers';
import { H3HexagonLayer } from '@deck.gl/geo-layers';
import Color from 'color';

const d3 = Object.assign(
    {},
    require('d3-array'),
    require('d3-scale'),
    require('d3-interpolate'),
    require('d3-scale-chromatic'),
    require('d3-color'),
);

export class H3ClusterDeck {
    constructor(map, data, initialView) {
        this.MAP = map;
        this.DATA = data;
        this.INITIAL_VIEW_STATE = initialView;

        this.canvas = 'deckgl';
        this.width = '100%';
        this.height = '100%';

        this.colorScheme = 'interpolateMagma'; // 'interpolateViridis';
        this.colorAccessor = d => d.metric;

        // https://github.com/d3/d3-scale-chromatic
        this.colourScale = d3
            .scaleSequential(d3[this.colorScheme])
            .domain(d3.extent(this.DATA, this.colorAccessor));
    }

    draw() {
        this.deck = new Deck({
            canvas: this.canvas,
            width: this.width,
            height: this.height,
            initialViewState: this.INITIAL_VIEW_STATE,
            controller: true,
            layers: [
                new GeoJsonLayer({
                    id: 'base-map',
                    data: this.MAP,
                    stroked: true,
                    filled: true,
                    extruded: false,
                    lineWidthMinPixels: 1,
                    opacity: 0.04,
                    getLineColor: [60, 60, 60],
                    getFillColor: [230, 230, 255],
                }),
                new H3HexagonLayer({
                    data: this.DATA,
                    id: 'h3-hexagon-layer',

                    pickable: true,
                    wireframe: false,
                    filled: true,
                    extruded: false,
                    opacity: 0.75,

                    getHexagon: d => d.hex,
                    getFillColor: d => Color(this.colourScale(this.colorAccessor(d))).color,

                    getLineColor: [255, 255, 255],
                    lineWidthMinPixels: 1,
                }),
            ],
        });
    }
}

export default H3ClusterDeck;
