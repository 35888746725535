import { Deck } from '@deck.gl/core';
import { GeoJsonLayer, ScatterplotLayer, ArcLayer } from '@deck.gl/layers';

export class CustomDeck {
    constructor(map, data, initialView) {
        this.MAP = map;
        this.DATA = data;
        this.INITIAL_VIEW_STATE = initialView;

        this.canvas = 'deckgl';
        this.width = '100%';
        this.height = '100%';
    }

    draw() {
        this.deck = new Deck({
            canvas: this.canvas,
            width: this.width,
            height: this.height,
            initialViewState: this.INITIAL_VIEW_STATE,
            controller: true,
            layers: [
                new GeoJsonLayer({
                    id: 'base-map',
                    data: this.MAP,
                    stroked: true,
                    filled: true,
                    lineWidthMinPixels: 1,
                    opacity: 0.04,
                    getLineColor: [60, 60, 60],
                    getFillColor: [73, 94, 100, 100],
                }),
                new ScatterplotLayer({
                    id: 'scatterplot-layer',
                    data: this.DATA,

                    opacity: 1,
                    stroked: true,
                    filled: true,
                    radiusScale: 6,
                    radiusMinPixels: 1,
                    radiusMaxPixels: 10,
                    lineWidthMinPixels: 1,
                    getPosition: f => [Number(f.Longitude), Number(f.Latitude)],
                    // getRadius: 50,
                    getRadius: d => Number(d['Gross Energy (GWh)']),
                    getFillColor: [0, 0, 0, 50],
                    getLineColor: [0, 0, 0, 255],
                    onHover: ({ object, x, y }) => {
                        // eslint-disable-next-line no-unused-vars
                        const tooltip = `Hello: ${x}, ${y}, ${object}`;
                    },


                    //     // Interactive props
                    pickable: true,
                    autoHighlight: true,
                    onClick: info => String(info['Gross Energy (GWh)']),
                }),

                new ArcLayer({
                    id: 'arcs',
                    data: this.DATA,

                    // Styles
                    getSourcePosition: () => [-73.5673, 45.5017], // London
                    getTargetPosition: f => [f.Longitude, f.Latitude],
                    getSourceColor: [0, 128, 200],
                    getTargetColor: [200, 0, 80],
                    getWidth: 1,
                    getHeight: 0.1,
                    getTilt: 90,
                    opacity: 0.003,
                }),
            ],
        });
    }
}

export default CustomDeck;
