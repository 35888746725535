/* eslint-disable no-use-before-define */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */

'using strict';

// JS
import { geoToH3, h3ToGeo, kRingDistances } from 'h3-js';
import { BaseDeck } from './js/base-deck';
import { CustomDeck } from './js/custom-deck';
import { HeatmapDeck } from './js/heatmap-deck';
import { H3ClusterDeck } from './js/h3-cluster-deck';
import { ScreenGridDeck } from './js/screen-grid-deck';

// CSS
import './css/base.scss';

const d3 = Object.assign({}, require('d3-fetch'), require('d3-array'), require('d3-selection'));

async function drawDeck(id) {
    const container = document.getElementById(id);

    if (container) {
        switch (id) {
        case 'base': {
            const drawing = new BaseDeck();
            drawing.draw();
            break;
        }

        case 'custom': {
            // Fetch data with d3
            const data = await d3.csv('../assets/data/wind-data.csv');
            const map = await d3.json(
                '../assets/data/world-medium.geo.json',
            );
            const country = map.features.filter(
                item => item.properties.iso_a3 === 'CAN',
            );

            const dataFilter = data.filter(f => f.Province === 'Quebec');

            // const country = map;

            const initialView = {
                latitude: 52,
                longitude: -70,
                zoom: 4,
                bearing: 0,
                pitch: 0,
            };
            const drawing = new CustomDeck(country, dataFilter, initialView);
            drawing.draw();
            break;
        }

        case 'heatmap': {
            // Fetch data with d3
            const data = await d3.csv('../assets/data/wind-data.csv');
            const map = await d3.json(
                '../assets/data/world-medium.geo.json',
            );
            const country = map.features.filter(
                item => item.properties.iso_a3 === 'CAN'
                        || item.properties.iso_a3 === 'USA',
            );

            // const dataFilter = data.filter(f => f.Province === 'Quebec');

            const initialView = {
                latitude: 52,
                longitude: -93.45,
                zoom: 3,
                bearing: 0,
                pitch: 0,
            };
            const drawing = new HeatmapDeck(country, data, initialView);
            drawing.draw();
            break;
        }

        case 'screengrid': {
            // Fetch data with d3
            const data = await d3.csv('../assets/data/wind-data.csv');
            const map = await d3.json(
                '../assets/data/world-medium.geo.json',
            );
            const country = map.features.filter(
                item => item.properties.iso_a3 === 'CAN'
                        || item.properties.iso_a3 === 'USA',
            );

            const initialView = {
                latitude: 52,
                longitude: -93.45,
                zoom: 3,
                bearing: 0,
                pitch: 0,
            };
            const drawing = new ScreenGridDeck(country, data, initialView);
            drawing.draw();
            break;
        }

        case 'h3cluster': {
            // Fetch data with d3
            const wind = await d3.csv('../assets/data/wind-data.csv');

            const data = await d3.json('../assets/data/h3clusters-2.json');
            const map = await d3.json(
                '../assets/data/world-medium.geo.json',
            );
            const country = map.features.filter(
                item => item.properties.iso_a3 === 'CAN'
                        || item.properties.iso_a3 === 'USA',
            );

            wind.forEach((d) => {
                const h3Index = geoToH3(
                    Number(d.Latitude),
                    Number(d.Longitude),
                    4,
                );
                d.hex = h3Index;
                d.hexIds = [];
                d.hexIds.push(h3Index);
            });

            const windMap = d3.rollup(wind, v => d3.sum(v, d => Number(d['Net Energy (GWh)'])), d => d.hex);
            const tempArray = Array.from(windMap);

            const windArray = [];

            tempArray.forEach((d, i) => {
                const obj = {
                    hex: d[0],
                    metric: d[1],
                    location: h3ToGeo(d[0]),
                };
                windArray.push(obj);
            });

            // console.log(windArray);

            const initialView = {
                latitude: 52,
                longitude: -93.45,
                zoom: 3,
                bearing: 0,
                pitch: 0,
            };

            const drawing = new H3ClusterDeck(country, windArray, initialView);
            drawing.draw();
            break;
        }

        default:
        }
    }
}


drawDeck('base');
drawDeck('custom');
drawDeck('heatmap');
drawDeck('screengrid');
drawDeck('h3cluster');
